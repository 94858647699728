<template>
  <ContentWrapper :fullHeight="true">
    <template v-slot:title>Market Data</template>
    <Container>
      <div class="page-options">
        <div class="page-options-form">
          <div class="form-group exchange">
            <label for="exchange">Exchange*</label>
            <v-select
              v-model="selectedExchange"
              :options="exchanges"
              placeholder="Exchange"
            ></v-select>
          </div>
          <div class="form-group product">
            <label for="product">Product*</label>
            <v-select
              v-model="selectedProducts"
              :options="products"
              placeholder="Product"
            ></v-select>
          </div>
          <div class="form-group market-data">
            <label for="productSymbol">Market Data*</label>
            <div class="market">
              <v-select
                placeholder="Market Data"
                v-model="selectedFileData"
                :options="marketData"
                label="filename"
                class="market-select"
              ></v-select>
              <input
                :style="{ display: 'none' }"
                ref="fileUpload"
                type="file"
                @change="onFileChanged"
                accept=".csv"
              />
              <base-button
                class="btn btn-grayDark market-btn"
                @click="$refs.fileUpload.click()"
            >
                Upload
              </base-button>
            </div>
          </div>
          <div class="button-container">
            <div class="button-container-one">
              <base-button @click="submitData" mode="compact" :disabled="this.selectedExchange == '' || this.selectedProducts == '' || this.selectedFile == ''">Add New</base-button>
            </div>
            <div class="button-container-two">
              <base-button @click="exportMarket" mode="compact" :disabled="!isUploaded">Export</base-button>
              <base-button mode="compact" :disabled="true">Edit </base-button>
              <base-button mode="compact" :disabled="!selectedFileData || !isUploaded" @click="deleteMarketData">Delete Market Data</base-button>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </ContentWrapper>
</template>

<script>
import { products, exchanges } from "@/services/api/others.js";
import { postMarketData, marketDataIndex, exportMarketData, deleteMarketData, updateMarketData} from "@/services/adminAPI/marketData.js";
import {eventBus} from '@/main.js';


export default {
  data() {
    return {
      exchanges: [],
      products: [],
      marketData: [],
      selectedExchange: "",
      selectedProducts: "",
      selectedFile: "",
      selectedFileData: "",
      market_data_file: "",
      isUploaded: null,
    };
  },
  methods: {
    onFileChanged(e) {

      this.selectedFileData = {
        filename: e.target.files[0].name
      }
    
      this.selectedFile = e.target.files[0];
    },
    clearInput(){
      this.selectedExchange = '';
      this.selectedProducts = '';
      this.selectedFile = '';
      this.selectedFileData = '';
      this.isUploaded = null;
    },
    marketDataDeselected(){
      console.log('trigger');
      this.selectedFile = '';
    },
    async submitData() {

      try {
        const data = new FormData();
        data.append("exchange", this.selectedExchange);
        data.append("product", this.selectedProducts);
        data.append("market_data_file", this.selectedFile);

        eventBus.$emit('loader',true);
        await postMarketData(data);
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'success',
          title: 'Market Data Added',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        })

        this.clearInput();

      } catch (error) {
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: error.response.data.userMessage
        })
      }
      
    },
    async exportMarket(){
      eventBus.$emit('loader',true);
      try {
        const request = await exportMarketData(this.selectedFileData.id)
        const a = document.createElement('a')
        a.style.display = "none"
        document.body.appendChild(a)
        
        let blob = new Blob([request.data], {type: "octet/stream"})
        let url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = this.selectedFileData.filename;
        a.click()
        eventBus.$emit('loader',false);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        eventBus.$emit('loader',false);
        this.$swal({
          icon: 'error',
          title: 'Oops!',
          text: error.response.data.userMessage
        })
      }

    },
    async getExchange(){
      let req = await exchanges();
      this.exchanges = req.data.response.exchanges;
    },
    async getProducts(exchange = ""){
      let req = await products(exchange)
      this.products = req.data.response.products
    },
    async deleteMarketData(){
      try {
        eventBus.$emit('loader',true);
        await deleteMarketData(this.selectedFileData.id);
        this.$swal({
          icon: 'success',
          title: 'Market Data Deleted',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
        })

        const req3 = await marketDataIndex()
        this.marketData = req3.data.response.marketData

        eventBus.$emit('loader',false);
        this.clearInput();
      } catch (error) {
        eventBus.$emit('loader',false);
      }
      
    }
  },
  async mounted() {
    this.getExchange()
    const req3 = await marketDataIndex()
    this.marketData = req3.data.response.marketData
  },
  watch:{
    selectedExchange(val){
      this.getProducts(val)
    },
    selectedFileData(val){
      //Check if selected from dropdown or uploaded
      val?.id ? this.isUploaded = true : this.isUploaded = false;

      //Dselect selectedFile if uncheck from vueselect
      if(val === null){
        this.selectedFile = '';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.save-button {
  float: right;
}

.page-options {
  &-form {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .market {
    display: flex;
    width: 100%;
    align-content: flex-end;
    &-select {
      width: calc(100% - 180px);

      @include respond(phone){
        width: 100%;
      }
    }
    &-btn {
      padding: 0.75rem;
      margin: auto;
      width: ($ten * 15);

      @include respond(phone){
        margin: 0 auto;
      }
    }

    @include respond(phone){
      display: block;
    }
  }
  .v-select {
    margin: $twelve 0;
  }
  .button-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    @include respond(tab-port){
      order: -1;
      margin-bottom: 2em;
    }
    &-one {
      display: flex;
      gap: 1em;
      @include respond(tab-port){
        display: none;
      }
    }
    &-two {
      display: flex;
      @include respond(phone){
        font-size: $twelve;
      }

      & > * {
        margin-left: 10px;
      }
    }
  }
}
</style>
